import * as React from 'react'

export const ClientsList = ({ clients }) => {
  if (!clients) return null
  const clientslist = clients.clientslist
  if (!clientslist) return null

  console.log('clients', clients)

  return (
      <p>
        {clientslist.map((client, index) => (
          <a key={index} href={client.client_link.url} target={client.client_link.target} className='client'>
            <span>{client.client_name}</span>
          </a>
        ))}
      </p>
  )
}
