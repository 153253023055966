import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
import { ClientsList } from '../components/ClientsList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHeart } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons'
import { PrismicRichText } from '@prismicio/react'

export const query = graphql`
  query MyHomeQuery {
    prismicBloghome {
      _previewable
      data {
        description {
          richText
        }
        headline {
          richText
        }
        image {
          url
        }
      }
    }
    prismicClientslist {
      data {
        clientslist {
          client_name
          client_link {
            url
            target
          }
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  if (!data) return null

  const home = data.prismicBloghome.data
  const clients = data.prismicClientslist.data

  return (
    <Layout>
      <div className="home-header container" data-wio-id={home.id}>
        <div className='personal-info'>
          <PrismicRichText field={home.headline.richText} />
          <PrismicRichText field={home.description.richText} />
        </div>
        <div className='contacts'>
          <a href='https://github.com/davideghz' title="If you know what I'm talking about">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href='https://it.linkedin.com/in/davideghezzi' title="If you don't">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div className="clients-list container">
        <p>Currently Product Director @<a target={'_blank'} rel="noreferrer" href='https://bakeca.it'>Bakeca.it</a>.
          <br/>
          Over the last years I&apos;ve been lucky enough to work with these incredible people:
        </p>
        <ClientsList clients={clients} />
        <p>
          Thanks for all the <FontAwesomeIcon icon={faHeart} color={'red'} /> and <FontAwesomeIcon icon={faCoffee} />! :)
        </p>
      </div>

      <div className="blog container">
        <p>Sometimes I post <Link to={'/posts'} >here</Link> my thoughts.</p>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(Homepage)
